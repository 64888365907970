import React from 'react';
import Layout from '../components/Layout';

const NotFoundPage = () => (
  <Layout
    title="Page Not Found"
    description="Sorry, but the page you were trying to view does not exist."
  >
    <h1>Page Not Found</h1>
    <h2>Sorry, but the page you were trying to view does not exist.</h2>
  </Layout>
);

export default NotFoundPage;
